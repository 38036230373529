import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Breadcrumbs, { Crumb } from "../components/breadcrumbs";
import PostInRow from "../components/cards/postInRow";
import LinkTag from "../components/post/tags";
import Grid from "@quandoo-dev/cookbook/lib/grid";
import { Heading } from "@quandoo-dev/cookbook/lib/typography";
import Divider from "@quandoo-dev/cookbook/lib/divider";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Pagination from "../components/pagination";
import useTranslation from "../hooks/useTranslation";
import { getOneTrustId } from '../utils/one-trust-ids';

const H1 = styled.h1`
  background-color: #f0f2f5;
  padding: 7px 16px;
  display: inline-block;
  border-radius: 5px;
  font-weight: 600;
  color: #2d384d;
  font-size: 32px;
  line-height: 40px;
  margin: 26px 0 14px 0;
  @media (min-width: 600px) {
    font-size: 36px;
    line-height: 40px;
    margin: 18px 0 14px 0;
  }
  @media (min-width: 1024px) {
    font-size: 45px;
    line-height: 56px;
  }
`;

const AllPostsTitle = styled.h3`
  color: #3a4354;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

interface BlogProps {
  data: any;
  pageContext: any;
}

const BlogTag: React.FC<BlogProps> = props => {
  const {
    data: {
      contentfulTag: { name, locale, slug },
      allContentfulPost: { nodes: posts },
      allContentfulTag: { nodes: tags },
    },
    pageContext: { numPosts, currentPage, numPages },
  } = props;
  const crumbs = [
    {
      // TODO: the string for "Home" should come from Contentful according to locale
      name: "Home",
      to: `/`,
      item: `${locale.domain}`,
    },
    {
      name: name,
      item: `${locale.domain}/tag/${slug}`,
    } as Crumb,
  ];

  // TODO: transform in reusable component
  const postItems = posts.map((post, index) => (
    <Grid.Item columns={[12, 12, 12, 3]} key={index}>
      <PostInRow
        post={post}
        hideDivider={posts.length - 1 == index}
        staticImage={null}
      ></PostInRow>
    </Grid.Item>
  ));

  return (
    <Layout locale={locale.slug}>
      <Helmet
        htmlAttributes={{
          lang: locale.languageCode,
        }}
        title={name + " | " + locale.name}
        titleTemplate={name + " | " + locale.name}
        link={[
          {
            rel: "canonical",
            key: locale.domain + "/tag/" + slug,
            href: locale.domain + "/tag/" + slug,
          },
        ]}
        meta={[
          //{ name: `description`, content: categoryDescription },
          { name: `robots`, content: `index, follow` },
          { name: `og:locale`, content: locale.languageCode },
          { name: `og:type`, content: `object` },
          { name: `og:title`, content: name + " | " + locale.name },
          //{ name: `og:description`, content: categoryDescription },
          { name: `og:site_name`, content: locale.name },
          { name: `og:url`, content: locale.domain + "/tag/" + slug },
          {
            name: `og:image`,
            content: posts[0] ? posts[0].previewImage.asset.fluid.src : "",
          },
          {
            name: `twitter:card`,
            content: posts[0] ? posts[0].previewImage.asset.fluid.src : "",
          },
          { name: `twitter:title`, content: name + " | " + locale.name },
          { name: `twitter:site`, content: "@" + locale.twitterAccountName },
        ]}
      >
        {locale.slug !== 'au' && <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charSet="UTF-8" data-domain-script={getOneTrustId(locale.slug)} ></script>}
        {locale.slug !== 'au' && <script type="text/javascript">function OptanonWrapper() { }</script>}
      </Helmet>
      <Grid.Row>
        <Grid.Item columns={[12, 12, 12, 12]}>
          <Breadcrumbs crumbs={crumbs} />
          <H1>{name}</H1>
        </Grid.Item>
      </Grid.Row>
      <Grid.Row mt="xxxl">
        <Grid.Item>
          <AllPostsTitle>
            {`${useTranslation(
              locale.slug,
              "taxonomy.all_articles"
            )} (${numPosts})`}
          </AllPostsTitle>
          <Divider
            color="#D8D8D8"
            mt={{ _: "xxs", md: "xxs", lg: "xxs" }}
            mb={{ _: "xs", md: "xs", lg: "xs" }}
          />
        </Grid.Item>
      </Grid.Row>
      <Grid.Row>{postItems}</Grid.Row>
      <Grid.Row mb="xxxl">
        <Grid.Item>
          <Pagination
            sourceUrl={`/tag/${slug}`}
            currentPage={currentPage}
            numPages={numPages}
          />
        </Grid.Item>
      </Grid.Row>
      <Heading size={6} color="secondary" mt="xxl">
        {useTranslation(locale.slug, "home.popular_tags")}
      </Heading>
      <Grid.Row>
        <Grid.Item columns={12} mb="xl">
          <LinkTag
            tags={tags.slice(0, 8)}
            showTitle={false}
            country={locale.slug}
          />
        </Grid.Item>
      </Grid.Row>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!, $localeSlug: String!, $skip: Int!, $limit: Int!) {
    contentfulTag(slug: { eq: $slug }, locale: { slug: { eq: $localeSlug } }) {
      name
      slug
      locale {
        name
        slug
        domain
        languageCode
        twitterAccountName
        logo {
          fluid {
            src
          }
        }
      }
    }
    allContentfulTag(
      filter: { locale: { slug: { eq: $localeSlug } } }
      sort: { fields: [post___tags___name], order: ASC }
    ) {
      nodes {
        name
        slug
        locale {
          slug
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
        imageUrl
        lang
      }
    }
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        locale: { slug: { eq: $localeSlug } }
        tags: { elemMatch: { slug: { eq: $slug } } }
      }
    ) {
      nodes {
        title
        slug
        description {
          description
        }
        previewImage {
          description
          asset {
            fluid(maxWidth: 536) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
        locale {
          slug
        }
        category {
          name
          slug
        }
        timeToRead
      }
    }
  }
`;

export default BlogTag;
